import axios from "axios";
import Utility from "../../../shared/utility";
import { store } from "../../../state-management/state-mgmt";
export default {
  getOrderList(call, obj) {
    return this.getData("/so/so_order_types", call, obj);
  },

  getOrderDraftList(call, userId) {
    return this.getData(`/so/so_temp_listbyid?UserId=${userId}&so_id=0&Pass=1`, call);
  },

  getOrderDraftById(call, userId, so_id) {
    return this.getData(`/so/so_temp_listbyid?UserId=${userId}&so_id=${so_id}&Pass=2`, call);
  },

  getDeleteDraftSO(call, userId, so_id, showMessage) {
    return this.getData(`/so/so_temp_delete?UserId=${userId}&so_id=${so_id}&Pass=2`, call, "", showMessage);
  },

  getDeleteAllDraftSO(call, userId, so_id, showMessage) {
    return this.getData(`/so/so_temp_delete?UserId=${userId}&so_id=${so_id}&Pass=2`, call, "", showMessage);
  },
  postSearchData(call, obj) {
    return this.getData("/so/so_search", call, obj);
  },
  getStatusData(call, userId) {
    return this.getData(`/so/so_order_status?userId=${userId}`, call);
  },
  postCustomerData(call, obj, showMessage, disableLoader) {
    return this.getData("/co/customer_data", call, obj, showMessage, disableLoader);
  },
  getReturnAddData(call, userId, proj_key, searchTest, bu_key, obj, showMessage, disableLoader, pass) {
    return this.getData(
      `/so/so_rtn_address?UserId=${userId}&proj_key=${proj_key}&SearchStr=${searchTest}&bu_key=${bu_key}&Pass=${pass}`,
      call,
      obj,
      showMessage,
      disableLoader
    );
  },
  postOrderTypeData(call, obj) {
    return this.getData("/so/so_order_types", call, obj);
  },

  postCarrierData(call, obj) {
    return this.getData("/so/so_service_levels", call, obj);
  },

  postAddCustomer(call, obj, showMessage) {
    return this.getData("/co/customer_upsert", call, obj, showMessage);
  },
  postAddReturnAddress(call, obj, showMessage) {
    return this.getData("/so/so_rtn_address_upsert", call, obj, showMessage);
  },

  postShipSearch(call, obj, showMessage, disableLoader) {
    return this.getData("/so/so_shippart_search", call, obj, showMessage, disableLoader);
  },
  postShipWareData(call, obj) {
    return this.getData("/so/so_ppid_validate", call, obj);
  },

  postBuildData(call, obj, showMessage, disableLoader) {
    return this.getData("/so/so_buildpart_search", call, obj, showMessage, disableLoader);
  },

  postReturnSearch(call, obj, showMessage, disableLoader) {
    return this.getData("/so/so_returnpart_search", call, obj, showMessage, disableLoader);
  },
  getOrderTypeData(call, userId, proj_key, so_id) {
    return this.getData(`/so/so_linetypelist?UserId=${userId}&ProjKey=${proj_key}&so_id=${so_id}`, call);
  },

  getValidateCustomer(call, userId, CustNo, proj_key, disableLoader) {
    return this.getData(`/co/validate_custno?UserId=${userId}&CustNo=${CustNo}&pass=1&proj_id=${proj_key}`, call, "", "", disableLoader);
  },

  getCustomerCount(call, userId, CustNo, proj_key) {
    return this.getData(`/co/validate_custno?UserId=${userId}&CustNo=${CustNo}&pass=3&proj_id=${proj_key}`, call, "");
  },
  getValidateReturn(call, userId, CustNo, proj_key, disableLoader) {
    return this.getData(`/co/validate_custno?UserId=${userId}&CustNo=${CustNo}&pass=2&proj_id=${proj_key}`, call, "", "", disableLoader);
  },

  getWarrentyData(call, userId) {
    return this.getData(`/so/so_warrantytype?UserId=${userId}`, call);
  },
  // Save call in Temporary Table
  postSoTempUpsert(call, obj, showMessage) {
    return this.getData("/so/so_temp_upsert", call, obj, showMessage);
  },

  postSoSubmitUpsert(call, obj, showMessage) {
    return this.getData("/so/so_order_create", call, obj, showMessage);
  },
  getBookmarkList(call, userId) {
    return this.getData(`/so/so_order_bookmark?UserId=${userId}&so_id=0&Pass=1`, call);
  },
  getDeleteBookmarkSO(call, userId, so_id, showMessage) {
    return this.getData(`/so/so_order_bookmark?UserId=${userId}&so_id=${so_id}&Pass=3`, call, "", showMessage);
  },
  getDeleteAllBookmarkSO(call, userId, so_id, showMessage) {
    return this.getData(`/so/so_order_bookmark?UserId=${userId}&so_id=${so_id}&Pass=4`, call, "", showMessage);
  },

  validateSONumber(call, userId, ProjKey, soNumber) {
    return this.getData(`so/so_order_validate?UserId=${userId}&proj_id=${ProjKey}&OrdNum=${soNumber}`, call);
  },
  postSoSearchData(obj) {
    let call = "post";
    return this.getData("/so/so_manage_so_types", call, obj);
  },
  // add ends Here

  //Common API
  getSalesOrderActionLog(So_key, UserId) {
    let call = "get";
    return this.getData(`/so/so_actionlog?So_key=${So_key}&UserId=${UserId}`, call, "");
  },

  // Edit API starts here

  async getData(url, call, obj, showMessage, disableLoader) {
    if (!disableLoader) {
      var LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    } else {
      LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
    }
    let res = {};
    // Get Call
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    }
    // Post Calle
    else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
        return error;
      });
    }

    if (res?.data?.body?.message != undefined || res?.data?.body?.message != null) {
      if (!res?.data?.body?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res.data.body.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.body.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: JSON.parse(res?.data?.body?.message).Result[0].Message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        return JSON.parse(res?.data?.body?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;

        return JSON.parse(res?.data?.body?.message);
      }
    } else if (res?.message) {
      return res?.response?.status;
    } else if (res?.data?.body?.message == null) {
      LoaderDialog.visible = false;
      return JSON.parse('{"message":"NA"}');
    } else {
      if (!res?.data?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res?.data?.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res?.data?.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse('{"message":"NA"}');
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        return JSON.parse(res?.data?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    }
  },
};
