import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import searchComp from ".././search-comp.vue";
import AddFavourite from "../../utility/js/addFav";
import salesService from ".././js/sales-order-service.js";
import moment from "moment";
import { bus } from "../../../main";
import commonApiService from "../../common/js/common-api-service";
export default {
  name: "salesOrderList",
  data() {
    return {
      showSearchComp: false,
      required: [(v) => !!v || "Field is required"],
      max3Rule: [(v) => (v || "").length >= 3 || "Min Length of 3 character", (v) => (v || "").length > 0 || "Min Length of 3 character"],
      selectedBusiness: "",
      numberRule: [
        (v) => parseInt(v) > 0 || "Field should be greater than 0",
        (v) => parseInt(v) < 2147483647 || "Field should be less than 2,147,483,647",
      ],
      selectedinbound: "",
      selectedOutbound: "",
      showStartDate: true,
      showEndDate: true,
      detailSearch: "",
      startDate: "",
      showDraft: true,
      showBkmark: true,
      isAdvanceForm: true,
      isFormValid: false,
      businessList: [],
      selectedProject: "",
      deleteDialog: false,
      selectedCountry: "",
      latestOrder: 10,
      countryList: [],
      outboundList: [],
      totalRecords: 0,
      inboundList: [],
      projectList: [],
      orderDraftList: [],
      bookmarkList: [],
      enddate: "",
      orderTypeList: [],
      selectedOrderType: [],
      drawer: true,
      sDate: "",
      EDate: "",
      backSlash: true,
      date: "",
      showFilter: false,
      menu: false,
      searchFlag: false,
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: new Date().toISOString().split("T")[0],
      maxEndDate: new Date(new Date().getTime()).toISOString().split("T")[0],
      searchData: "",
      advSearch: "",
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      selectedStatus: [],
      deleteMsg: "Are you sure you want to delete item?",
      search: "",
      searchObj: [],
      panel: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      draftHeader: [
        {
          text: "Project",
          value: "Project",
          class: "primary customwhite--text",
        },
        {
          text: "Business",
          value: "Business",
          class: "primary customwhite--text",
        },
        {
          text: "Created By",
          value: "CBy",
          class: "primary customwhite--text",
        },
        {
          text: "Created Date",
          value: "CDate",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      bookmarkHeader: [
        {
          text: "Order Number",
          align: "start",
          value: "sono",
          class: "primary customwhite--text",
        },
        {
          text: "Order Type",
          align: "start",
          value: "order_type",
          class: "primary customwhite--text",
        },
        {
          text: "Project",
          align: "start",
          value: "project",
          class: "primary customwhite--text",
        },
        {
          text: "Business",
          align: "start",
          value: "business",
          class: "primary customwhite--text",
        },

        {
          text: "Customer Id",
          align: "start",
          value: "customer_id",
          class: "primary customwhite--text",
        },
        {
          text: "Outbound Status",
          align: "start",
          value: "outbound_status",
          class: "primary customwhite--text",
        },
        {
          text: "Inbound Status",
          align: "start",
          value: "inbound_status",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      route: "",
      actionList: [],
      mainPage: "",
      favouriteList: [],
      pageUrl: [],
      showAdd: false,
      subPage: "",
      userId: EncryptUtility.localStorageDecrypt("userID"),
      so_id: "",
      deleteItem: "",
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    let projectData = await commonApiService.getProject("get", this.userId);
    this.projectList = projectData.ProjectInfo;
    let countryData = await commonApiService.countryListNew(this.userId, "", 1, "get");
    this.countryList = countryData.CountryInfo;
    let orderDraftData = await salesService.getOrderDraftList("get", this.userId);
    this.orderDraftList = orderDraftData;
    this.showDraft = this.orderDraftList.length == undefined ? false : true;
    // if we want to show Draft Table
    if (this.showDraft) {
      // Converting the Date to MM/DD/YYYY/ HH:mm:ss Format
      this.orderDraftList.forEach((element) => {
        element.CDate = Utility.convertESTToLocal(element.CDate);
      });
    }
    this.totalRecords = this.orderDraftList.length;
    this.showDraft = this.orderDraftList.length == undefined ? false : true;
    let bookmarkData = await salesService.getBookmarkList("get", this.userId);
    this.bookmarkList = bookmarkData;
    this.showBkmark = this.bookmarkList.length == undefined || Object.keys(this.bookmarkList).length === 0 ? false : true;
    if (this.showBkmark) {
      // Converting the Date to MM/DD/YYYY/ HH:mm:ss Format
      this.bookmarkList.forEach((element) => {
        element.cdate = Utility.convertESTToLocal(element.cdate);
      });
    }
    this.bookmarktotalRecords = this.bookmarkList.length;
    // To get Order Type List

    let statusData = await salesService.getStatusData("get", this.userId);
    this.outboundList = statusData.OutboundStatus;
    this.inboundList = statusData.InBoundStatus;
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("MM/DD/YYYY") : moment(new Date().getTime() - 86400000).format("MM/DD/YYYY");
    },
    computedEndDateFormattedMomentjs() {
      return this.enddate ? moment(this.enddate).format("MM/DD/YYYY") : moment(new Date()).format("MM/DD/YYYY");
    },
    largeScreen() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    smallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    if (EncryptUtility.localStorageDecrypt("SOSearch")) {
      this.showSearchComp = true;
      this.searchObj = JSON.parse(EncryptUtility.localStorageDecrypt("SOSearch"));
      if (this.searchObj.FilterType == 1) {
        this.search = this.searchObj.SearchStr
      }
      else if (this.searchObj.FilterType == 3) {
        this.detailSearch = this.searchObj.SearchStr
      }
      else {
        this.advSearch = this.searchObj.SearchStr
      }

      if (this.searchObj.AdvJson) {
        this.drawer = true;
        (this.selectedinbound = JSON.parse(this.searchObj.AdvJson).inStatus),
          (this.selectedOutbound = JSON.parse(this.searchObj.AdvJson).outStatus),
          (this.selectedOrderType = JSON.parse(this.searchObj.AdvJson).ordType);
        this.selectedProject = JSON.parse(this.searchObj.AdvJson).Proj_Key;
        this.selectedBusiness = JSON.parse(this.searchObj.AdvJson).Business;
        this.selectedCountry = JSON.parse(this.searchObj.AdvJson).Country;
        this.date = JSON.parse(this.searchObj.AdvJson).FromDate;
        this.date = this.date.substr(0, 10);
        this.enddate = JSON.parse(this.searchObj.AdvJson).ToDate;
        this.enddate = this.enddate.substr(0, 10);
        this.latestOrder = JSON.parse(this.searchObj.AdvJson).TopCount;
        this.setEndDate();
        this.projectSelected(this.selectedProject);
        this.$vuetify.goTo(0);
      }
    }
    this.$refs.salesSearchForm?.resetValidation();
  },
  methods: {
    //to check if its a number of not
    isNumber(event) {
      let value = Utility.isNumber(event)
      return value
    },
    setEndDate() {
      let minEndDate = new Date(this.date);
      this.minEndDate = new Date(minEndDate.getTime() + 86400000).toISOString().substr(0, 10);
      let maxSDate = new Date(this.maxStartDate);
      this.maxEndDate = new Date(maxSDate.getTime() + 0).toISOString().substr(0, 10);
    },
    soEdit(item) {
      this.$router.push({
        name: "salesOrderDetails",
        params: {
          id: btoa(item.so_id.toString()),
        },
      });
    },
    // Action based
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    // Add to Favouites
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    // To get the data of Dropdowns
    onSearchClick(search) {
      this.advSearch = "";
      this.detailSearch = "";
      this.searchObj = [];
      this.showSearchComp = true;
      this.search = search.trim();
      this.searchFlag = true;
      let AdvanceObj = {
        inStatus: this.selectedinbound,
        outStatus: this.selectedOutbound,
        ordType: this.selectedOrderType,
        Proj_Key: this.selectedProject,
        Business: this.selectedBusiness,
        Country: this.selectedCountry,
        FromDate: this.computedDateFormattedMomentjs,
        ToDate: this.computedEndDateFormattedMomentjs,
        TopCount: this.latestOrder,
      };
      if (!this.searchFlag) {
        this.searchObj = {
          UserId: this.userId,
          SearchStr: search,
          PageNumber: 1,
          AdvJson: JSON.stringify(AdvanceObj),
          FilterType: 2,
        };
      } else {
        this.searchObj = {
          UserId: this.userId,
          SearchStr: search,
          PageNumber: 1,
          AdvJson: "",
          FilterType: 1,
        };
      }
      this.$refs.detailSearchForm?.resetValidation();
      EncryptUtility.localStorageEncrypt("SOSearch", JSON.stringify(this.searchObj));
      bus.$emit("search", this.searchObj);
    },
    // On Project selection fill the business list
    async projectSelected(project) {
      let obj = {
        UserId: this.userId,
        ProjKey: project,
        BuKey: 0,
        IncludeEmptyBU: 0,
        WithinSameLocation: 0,
        loca_key: 0,
      };
      let businessData = await commonApiService.postBusinessData("post", obj);
      this.businessList = businessData.map((x) => ({
        bu_id: x.Bu_id,
        business: x.Business,
      }));
      let object = {
        UserId: this.userId,
        bu_key: 0, // DO not Delete
        pass: 3, // Fo not Delete
        project_key: project,
      };
      let orderData = await salesService.getOrderList("post", object);
      this.orderTypeList = orderData?.OrderTypes?.map((x) => ({
        ord_code: x.ord_code,
        ordCodedesc: x.ordCodedesc,
      }));
    },
    //Custom Search apply filter action
    async onClickSearchApply() {
      this.detailSearch = "";
      if (this.$refs.filterForm?.validate()) {
        !this.date ? (this.sDate = new Date(new Date().getTime() - 86400000).toISOString().substr(0, 10)) : (this.sDate = this.date);
        this.sDate = Utility.convertLocalToUTC(this.sDate);
        let endDate = Utility.convertLocalToUTC(this.enddate);
        this.searchObj = [];
        this.searchFlag = false;
        this.showSearchComp = true;
        let AdvanceObj = {
          inStatus: this.selectedinbound,
          outStatus: this.selectedOutbound,
          ordType: this.selectedOrderType,
          Proj_Key: this.selectedProject,
          Business: this.selectedBusiness,
          Country: this.selectedCountry,
          FromDate: this.sDate,
          ToDate: endDate,
          TopCount: this.latestOrder,
        };
        this.search = "";
        this.searchObj = {
          UserId: this.userId,
          SearchStr: this.advSearch,
          PageNumber: 1,
          AdvJson: JSON.stringify(AdvanceObj),
          FilterType: 2,
        };
        // Ant change in Advance filter the bus will emit the data which is consume in search-comp
        EncryptUtility.localStorageEncrypt("SOSearch", JSON.stringify(this.searchObj));
        bus.$emit("search", this.searchObj);
        this.$vuetify.goTo(0);
        this.$refs.salesSearchForm?.resetValidation();
        this.$refs.detailSearchForm?.resetValidation();
      }
    },
    async onClickDetailSearch() {
      this.search = "";
      this.searchObj = {
        UserId: this.userId,
        SearchStr: this.detailSearch,
        PageNumber: 1,
        AdvJson: "",
        FilterType: 3,
      };
      EncryptUtility.localStorageEncrypt("SOSearch", JSON.stringify(this.searchObj));
      bus.$emit("search", this.searchObj);
      this.$refs.salesSearchForm?.resetValidation();


    },
    // click on New Add SO
    newAddClicked() {
      this.$router.push(`/sales-order-add`);
    },
    // on Click of eye  of Drafy Order
    onClickSODetails(item) {
      let so_id = item.so_id;
      this.$router.push(`/sales-order-add/${btoa(so_id.toString())}`);
    },
    //Order Draft section: Delete Draft Orders
    onClickDraftDelete(item) {
      this.deleteMsg = "Are you sure you want to delete draft order?";
      this.deleteDialog = true;
      this.so_id = item.so_id;
      this.deleteItem = "Draft";
    },
    // Delete the So Draft
    async deleteSODraft() {
      await salesService.getDeleteDraftSO("get", this.userId, this.so_id, true);
      this.deleteDialog = false;
      // to Refresh the Table after Delete
      let orderDraftData = await salesService.getOrderDraftList("get", this.userId);
      if (orderDraftData?.message == "NA") {
        this.totalRecords = 0;
        this.showDraft = false;
      } else {
        this.orderDraftList = orderDraftData;
        this.orderDraftList.forEach((element) => {
          element.CDate = Utility.convertESTToLocal(element.CDate);
        });
        this.showDraft = this.orderDraftList.length == undefined ? false : true;
        this.totalRecords = this.orderDraftList.length;
      }
    },
    //Delete All SO
    onClickAllDraftDelete() {
      this.deleteMsg = "Are you sure you want to delete all draft order?";
      this.deleteDialog = true;
      this.deleteItem = "DraftAll";
    },
    //API call for Delete All SO
    async onClickDeleteAllDraft() {
      let draftData = await salesService.getDeleteAllDraftSO("get", this.userId, 0, true);
      this.deleteDialog = false;
      if (draftData?.Result) {
        this.totalRecords = 0;
        this.showDraft = false;
        this.orderDraftList = [];
      }
    },
    //Bookmarked items
    onBookmarkClickDelete(item) {
      this.deleteMsg = "Are you sure you want to remove bookmarked item?";
      this.deleteDialog = true;
      this.so_id = item.so_id;
      this.deleteItem = "BookMark";
    },
    //Bookmarked Order delete
    async deleteBookMarkDraft() {
      await salesService.getDeleteBookmarkSO("get", this.userId, this.so_id, true);
      this.deleteDialog = false;
      // to Refresh the Table after Delete
      let bookmarkData = await salesService.getBookmarkList("get", this.userId);
      if (bookmarkData?.message == "NA") {
        this.bookmarktotalRecords = 0;
        this.showBkmark = false;
      } else {
        this.bookmarkList = bookmarkData;
        this.bookmarkList.forEach((element) => {
          element.cdate = Utility.convertESTToLocal(element.cdate);
        });
        this.bookmarktotalRecords = this.bookmarkList.length;
        this.showBkmark = this.bookmarkList.length == undefined ? false : true;
      }
    },
    // Delete ALl Bookmark
    onBookmarkClickDeleteAll() {
      this.deleteMsg = "Are you sure you want to remove all bookmarked item?";
      this.deleteDialog = true;
      this.deleteItem = "BookMarkAll";
    },
    // APi Call to delete All Bookmark
    async onClickDeleteAllBookMark() {
      let bookmarkData = await salesService.getDeleteAllBookmarkSO("get", this.userId, 0, true);
      this.deleteDialog = false;
      if (bookmarkData?.Result) {
        this.bookmarktotalRecords = 0;
        this.bookmarkList = [];
      }
    },
    // Redirect to SO details  View
    onClickBookmarkDetails(item) {
      let so_id = item.so_id;
      this.$router.push(`/sales-order-details/${btoa(so_id.toString())}`);
    },
    // CLear Filter Functionaltity
    clearFilter() {
      this.$refs.salesSearchForm?.resetValidation();
      this.$refs.detailSearchForm?.resetValidation();
      this.$refs.filterForm?.resetValidation();
      this.showStartDate = false;
      this.showEndDate = false;
      this.$nextTick(() => {
        this.showStartDate = true;
        this.date = null;
        this.showEndDate = true;
        this.enddate = null;
      });
      this.detailSearch = "";
      this.selectedinbound = "";
      this.selectedOutbound = "";
      this.selectedOrderType = "";
      this.selectedProject = "";
      this.selectedBusiness = "";
      this.selectedCountry = "";
      this.computedDateFormattedMomentjs = "";
      this.computedEndDateFormattedMomentjs = "";
      this.date = "";
      this.enddate = "";
      this.advSearch = "";
      EncryptUtility.localStorageEncrypt("SOSearch", "");
      this.maxStartDate = new Date().toISOString().split("T")[0];
      this.minEndDate = new Date().toISOString().split("T")[0];
      this.maxEndDate = new Date(new Date().getTime()).toISOString().split("T")[0];
      let clearData = true
      bus.$emit("clearData", clearData);

    },
  },
  components: {
    breadcrumbComp,
    searchComp,
  },
};
